import React from "react"
import Link from "./components/link"
import { preToCodeBlock } from "mdx-utils"
import Iframe from "./components/iframe"
import tw from "twin.macro"
import Code, { InlineCode } from "./components/code"
import { createHeading } from "./components/heading"

export default {
  h2: createHeading("h2"),
  h3: createHeading("h3"),
  h4: createHeading("h4"),
  a: Link,
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
  iframe: Iframe,
  inlineCode: props => <InlineCode {...props} />,
  table: ({ children, ...rest }) => (
    <table css={tw`table-auto`} {...rest}>
      {children}
    </table>
  ),
}
