import "tailwindcss/dist/base.min.css"
import SmoothScroll from "smooth-scroll"

import { wrapRootElement as wrap } from "./src/wrapRootElement"

const isMac = navigator.appVersion.indexOf("Mac") != -1
/**
 *
 */
if (isMac === false) {
  require("./src/assets/theme/scrollbar.css")
}

new SmoothScroll("a[data-scroll]", {
  speed: 500,
  speedAsDuration: true,
  updateURL: true, // Update the URL on scroll
  clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
})

export const wrapRootElement = wrap
