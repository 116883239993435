/* eslint-disable */
import React from "react"
import { MDXProvider } from "@mdx-js/react"
import components from "./components"

const App = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}

const wrapRootElement = ({ element }) => <App {...{ element }} />

export { wrapRootElement }
