import React, { useState } from "react"
import copyToClipboard from "./../../utils/copy-to-clipboard"
import useUpdateEffect from "./../../hooks/use-update-effect"
import { ClipboardCopyIcon } from "@heroicons/react/outline"
import tw from "twin.macro"

const CopyButton = ({ codeString }) => {
  const [copied, setCopied] = useState(false)

  useUpdateEffect(() => {
    const id = setTimeout(() => {
      setCopied(false)
    }, 4000)

    return () => {
      clearTimeout(id)
    }
  }, [copied])

  const handleClick = () => {
    setCopied(true)
    copyToClipboard(codeString)
  }

  return (
    <button
      css={[
        tw`border-none outline-none text-white w-6 h-6 absolute top-1 right-2 hover:text-opacity-70`,
        copied ? tw`text-indigo-500` : null,
      ]}
      title="Copy code"
      onClick={handleClick}
      disabled={copied}
    >
      <ClipboardCopyIcon />
    </button>
  )
}

export default CopyButton
