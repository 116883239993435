import * as React from "react"
import { ErrorBoundary } from "react-error-boundary"
import tw from "twin.macro"
import { ExternalLinkIcon } from "@heroicons/react/outline"

function ErrorFallback() {
  return (
    <div
      css={tw`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded`}
      role="alert"
    >
      <p>Sorry this is not available now 😢</p>
    </div>
  )
}

const Iframe = ({ src }) => {
  return (
    <div css={tw`relative `}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <iframe src={src} css={tw`w-full h-96`} />
        <a
          target="_blank"
          href={src}
          css={tw`text-xs text-blue-500 flex gap-2 items-center my-2`}
        >
          <span> Original source</span>

          <ExternalLinkIcon css={tw`w-4 h-4`} />
        </a>
      </ErrorBoundary>
    </div>
  )
}

export default Iframe
