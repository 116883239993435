import { Link as GatsbyLink } from "gatsby"
import isAbsoluteURL from "../utils/is-absolute-url"
import tw from "twin.macro"
import { ExternalLinkIcon } from "@heroicons/react/outline"
import * as React from "react"

function Link({ to = "", href = to, children, ...props }) {
  const isAbsoluteLink = isAbsoluteURL(href)

  return isAbsoluteLink ? (
    <a
      {...props}
      target="_blank"
      href={href}
      css={tw`inline-flex gap-2 items-center`}
    >
      {children}
      <ExternalLinkIcon css={tw`w-4 h-4`} />
    </a>
  ) : (
    <GatsbyLink {...props} to={href}>
      {children}
    </GatsbyLink>
  )
}

export default Link
