import * as React from "react"
import styled from "@emotion/styled"
import createAnchor from "../utils/create-anchor"

const Heading = styled.h2``

export const createHeading = tag => props => {
  const { children } = props
  const id = createAnchor(children)
  return <Heading as={tag} {...props} id={id} />
}
