const ABSOLUTE_URL_REGEX = /^[a-zA-Z][a-zA-Z\d+\-.]*?:/

// Windows paths like `c:\`
const WINDOWS_PATH_REGEX = /^[a-zA-Z]:\\/

export default function isAbsoluteUrl(url) {
  if (typeof url !== "string") {
    throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``)
  }

  if (WINDOWS_PATH_REGEX.test(url)) {
    return false
  }

  return ABSOLUTE_URL_REGEX.test(url)
}
