import styled from "@emotion/styled"
import tw from "twin.macro"

export const Pre = styled.pre`
  ${tw`box-content dark:(border border-gray-500)`}
`

export const Line = styled.div`
  display: table-row;
`

export const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`

export const LineContent = styled.span`
  display: table-cell;
`
