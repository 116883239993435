import * as React from "react"
import theme from "prism-react-renderer/themes/nightOwl"
import Highlight, { defaultProps } from "prism-react-renderer"
import { Pre, Line, LineNo, LineContent } from "./styled"
import CopyButton from "./copy-button"

const Code = ({ codeString, language }) => {
  return (
    <React.Fragment>
      <Highlight
        {...defaultProps}
        theme={theme}
        code={codeString}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={style}>
            <CopyButton codeString={codeString} />
            {tokens.map((line, i) => (
              <Line key={i} {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                <LineContent>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </LineContent>
              </Line>
            ))}
          </Pre>
        )}
      </Highlight>
    </React.Fragment>
  )
}

export default Code
