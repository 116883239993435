import tw from "twin.macro"
import React from "react"
import styled from "@emotion/styled"

const StyledInlineCode = styled.code`
  ${tw`p-2 font-mono`}
  &:before,
  &:after {
    content: "" !important;
  }
`
const InlineCode = props => {
  return <StyledInlineCode className="inline-code" {...props} />
}

export default InlineCode
